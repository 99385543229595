<template>
  <el-dialog
      :title="`${$t('Delete')} ${ title }`"
      :visible.sync="deleteModal"
      width="40%"
      @close="closed"
      center>
    <span>{{ $t('Are you want delete the') }} {{ title }}: <strong>{{ item['name'] }}</strong>?</span>
    <span slot="footer" class="dialog-footer">
        <el-button @click="deleteModal = false">{{ $t('Cancel') }}</el-button>
        <el-button :loading="deleting" type="danger" @click="remove">{{ $t('Delete') }}</el-button>
      </span>
  </el-dialog>
</template>



<script>
export default {
  name: "Delete",
  props: {
    title: {
      type: String,
      default: ''
    },
    deleting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteModal: false,
      item: {
        name: 'Something'
      }
    }
  },
  methods: {
    call (item) {
      this.item = item
      this.deleteModal = true
    },
    closed () {
      this.deleteModal = false
      this.item = { name: 'Something' }
    },
    remove() {
      this.$emit('removed', this.item)
    }
  }
}
</script>

<style scoped lang="scss">

</style>