import ai from "@/utils/ai";

export default {
    state: {
        uploading: false,
        percent: 0
    },
    getters: {
        uploading: state => state['uploading'],
        uploadPercent: state => state['percent']
    },
    mutations: {
        UPLOADING (state, percent) {
            state.uploading = true
            state.percent = percent
        },
        UPLOADING_DONE (state) {
            state.uploading = false
            state.percent = 0
        }
    },
    actions: {
        uploadingAction({ commit }, payload) {
            commit('UPLOADING', payload)
        },
        async upload ({ commit }, payload) {
            const data = await ai.post('/upload', payload)
            window.notify({
                title: 'Success',
                message: 'File uploaded successfully.',
                type: 'success'
            })
            commit('UPLOADING_DONE')
            return data
        }
    }
}