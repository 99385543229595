import Vue from 'vue'
import Vuex from 'vuex'
import crud from "@/store/crud";
import alert from "@/store/alert";
import auth from "@/store/auth";
import upload from "@/store/upload";
import wizart from "@/store/wizart";
import sync from "@/store/sync";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    auth,
    upload,
    wizart,
    sync,
    products: crud('products'),
    categories: crud('categories'),
    colors: crud('colors'),
    sizes: crud('sizes'),
    brands: crud('brands'),
    shops: crud('shops'),
    users: crud('users'),
  }
})
