import aiw from "@/utils/aiw";
import ai from "@/utils/ai";

export default {
    state: {
        brands: [],
        products: [],
        collections: [],
        loaders: {
            brands: false,
            products: false,
            collections: false
        },
        productPagination: null
    },
    getters: {
        loadingFetchBrands: state => state['loaders'].brands,
        loadingFetchProducts: state => state['loaders'].products,
        loadingFetchCollections: state => state['loaders'].collections,
        wizartProducts: state => state['products'],
        wizartBrands: state => state['brands'],
        wizartCollections: state => state['collections'],
        wizartProductsPagination: state => state['productPagination'],
    },
    mutations: {
        setBrands(state, payload) {
            state['brands'] = payload
        },
        setProducts(state, payload) {
            state['products'] = payload
        },
        setCollections(state, payload) {
            state['collections'] = payload
        },
        productPagination(state, payload) {
            state['productPagination'] = payload
        },
        setLoader(state, payload) {
            // console.log('payload', payload)
            state['loaders'][payload.model] = payload.value
        }
    },
    actions: {
        async getWizartBrands({commit}) {
            try {
                commit('setLoader', {model: 'brands', value: true})
                const response = await ai.get('/brand-ws', {
                    filters: {
                        is_visible: {$eq: true}
                    },
                    pagination: {
                        page: 1,
                        pageSize: 1000
                    }
                })
                commit('setBrands', response.data)
                commit('setLoader', {model: 'brands', value: false})
                return response.data
            } catch (e) {
            }

        },
        async getWizartProducts({commit}, payload) {
            const {collection, brand} = payload
            try {
                commit('setLoader', {model: 'products', value: true})
                const response = await ai.get('/product-ws', {
                    filters: {
                        brand: {
                            uuid: {$eq: brand}
                        },
                        collection: {
                            uuid: {$eq: collection}
                        }
                    },
                    pagination: {
                        page: 1,
                        pageSize: 1000
                    },
                    populate: '*'
                })
                commit('setProducts', response.data)
                commit('setLoader', {model: 'products', value: false})
                return response.data
            } catch (e) {
            }
        },
        async getWizartCollections({commit}, payload) {
            try {
                commit('setLoader', {model: 'collections', value: true})
                const response = await ai.get('/collection-ws', {
                    filters: {
                        brand: {
                            uuid: {$eq: payload}
                        },
                        is_visible: {$eq: true}
                    },
                    pagination: {
                        page: 1,
                        pageSize: 1000
                    }
                })
                commit('setCollections', response.data)
                commit('setLoader', {model: 'collections', value: false})
                return response.data
            } catch (e) {
            }
        }
    }
}