import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "@/locales";
import store from './store'
import ElementUI from 'element-ui';
import PortalVue from 'portal-vue'

import { Notification } from "element-ui";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import Delete from "@/components/Delete.vue";
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

Vue.use(PortalVue)
Vue.config.productionTip = false
// import "element-ui/packages/theme-chalk/src/index";
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import './global.css'
locale.use(lang)

Vue.use(ElementUI);
// Vue.use(LoadScript);
window.notify = Notification;
Vue.prototype.$iUrl = process.env.VUE_APP_BASE_IMAGE_URL
Vue.prototype.$uploadUrl = process.env.VUE_APP_BASE_URL + '/upload'
// Vue.prototype.$uploadUrl = 'https://api-test.growz.uz' + '/upload'
Vue.prototype.$dParse = (date) => dayjs(date).format('DD-MM-YYYY')
Vue.component("Pagination", Pagination);
Vue.component("EmptyBox", EmptyBox);
Vue.component("Remover", Delete);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
