import ai from "@/utils/ai";
import ls from '@/utils/ls'
import Vue from 'vue'
import router from "@/router";
import i18n from '@/locales'
export default {
    state: {
        user: null,
        token: null,
        loading: false
    },
    getters: {
        user: state => state['user'],
        token: state => state['token'],
        loadAuth: state => state['loading']
    },
    mutations: {
        SET_TOKEN (state, payload) {
            state.token = payload
        },
        SET_LOADER (state, payload) {
            state.loading = payload
        },
        SET_USER (state, payload) {
            state.user = payload
        },
    },
    actions: {
        async login ({ commit, dispatch }, payload) {
            commit('SET_LOADER', true)
            try {
                const response = await ai.post('/auth/local', payload)
                await ls.s('token', response['jwt'])
                commit('SET_TOKEN', response['jwt'])
                commit('SET_LOADER', false)
                await dispatch('getMe').then(res => {
                    const role = res['role']['description']
                    if (role === 'admin') {
                        router.push({ name: 'shops' })
                    } else {
                        router.push({ name: 'products' })
                    }
                    dispatch('alert', {
                        type: 'success',
                        title: 'Success',
                        content: i18n.t('You have successfully logged in')
                    })
                })
            } catch (e) {
                // console.log('call')
                commit('SET_LOADER', false)
            }
        },
        async register ({ commit, dispatch }, payload) {
            commit('SET_LOADER', true)
            try {
                const response = await ai.post('/auth/local/register', payload)
                commit('SET_LOADER', false)
                dispatch('alert', {
                    type: 'success',
                    title: 'Success',
                    content: i18n.t('You have successfully registered')
                })
                return response
            } catch (e) {
                commit('SET_LOADER', false)
                return 'error'

            }
        },
        async logOut ({ commit }) {
            await ls.r('token')
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            return 'logged out'

        },
        async getMe ({ commit }) {
            try {
                const _me = await ai.get('/users/me', { populate: '*' })
                Vue.prototype.$user = _me
                commit('SET_USER', _me)
                return _me
            } catch (e) {
                return 'error'
            }
        }
    }
}