<script>
export default {
  name: "auth"
}
</script>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style scoped lang="scss">

</style>