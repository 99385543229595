
export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        // redirect: '/products',
        meta: {
            icon: 'el-icon-s-data',
            title: 'Dashboard',
            family: 'home',
            auth: true,
            role: ['admin', 'shop']
        },
    },
    {
        path: '/products',
        name: 'products',
        redirect: '/products/list',
        component: () => import('../views/view.vue'),
        meta: {
            icon: 'el-icon-s-goods',
            title: 'Products Page',
            family: 'products',
            auth: true,
            role: ['shop']
        },
        children: [
            {
                path: 'list',
                name: 'list-product',
                component: () => import('../views/ProductListing.vue'),
                meta: {
                    title: 'Products Page',
                    family: 'products',
                    auth: true
                }
            },
            {
                path: 'add',
                name: 'add-product',
                component: () => import('../views/Product.vue'),
                meta: {
                    title: 'Add Product',
                    family: 'products',
                    auth: true
                }
            },
            {
                path: 'edit/:id',
                name: 'edit-product',
                component: () => import('../views/Product.vue'),
                meta: {
                    title: 'Edit Product',
                    family: 'products',
                    auth: true
                }
            }
        ]
    },
    {
        path: '/shops',
        name: 'shops',
        redirect: '/shops/list',
        component: () => import('../views/view.vue'),
        meta: {
            icon: 'el-icon-s-shop',
            title: 'Shops Page',
            family: 'shops',
            auth: true,
            role: ['admin']
        },
        children: [
            {
                path: 'list',
                name: 'list-shops',
                component: () => import('../views/Shops.vue'),
                meta: {
                    title: 'Shops List',
                    family: 'shops',
                    auth: true
                }
            },
            {
                path: 'add',
                name: 'add-shops',
                component: () => import('../views/Shop.vue'),
                meta: {
                    title: 'Add Shop',
                    family: 'shops',
                    auth: true
                }
            },
            {
                path: 'edit/shop/:shop/owner/:owner',
                name: 'edit-shops',
                component: () => import('../views/Shop.vue'),
                meta: {
                    title: 'Edit Shop',
                    family: 'shops',
                    auth: true
                }
            }
        ]
    },
    // {
    //     path: '/colors',
    //     name: 'colors',
    //     redirect: '/colors/list',
    //     component: () => import('../views/view.vue'),
    //     meta: {
    //         icon: 'el-icon-s-opportunity',
    //         title: 'Colors Page',
    //         family: 'colors',
    //         auth: true
    //     },
    //     children: [
    //         {
    //             path: 'list',
    //             name: 'list-colors',
    //             component: () => import('../views/Colors.vue'),
    //             meta: {
    //                 title: 'Colors Page',
    //                 family: 'colors',
    //                 auth: true
    //             }
    //         },
    //         {
    //             path: 'add',
    //             name: 'add-colors',
    //             component: () => import('../views/Color.vue'),
    //             meta: {
    //                 title: 'Add Color',
    //                 family: 'colors',
    //                 auth: true
    //             }
    //         },
    //         {
    //             path: 'edit/:id',
    //             name: 'edit-colors',
    //             component: () => import('../views/Color.vue'),
    //             meta: {
    //                 title: 'Edit Color',
    //                 family: 'colors',
    //                 auth: true
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/brands',
    //     name: 'brands',
    //     redirect: '/brands/list',
    //     component: () => import('../views/view.vue'),
    //     meta: {
    //         icon: 'el-icon-star-off',
    //         title: 'Brands Page',
    //         family: 'brands',
    //         auth: true
    //     },
    //     children: [
    //         {
    //             path: 'list',
    //             name: 'list-brands',
    //             component: () => import('../views/Brands.vue'),
    //             meta: {
    //                 title: 'Brands Page',
    //                 family: 'brands',
    //                 auth: true
    //             }
    //         },
    //         {
    //             path: 'add',
    //             name: 'add-brands',
    //             component: () => import('../views/Brand.vue'),
    //             meta: {
    //                 title: 'Add Brand',
    //                 family: 'brands',
    //                 auth: true
    //             }
    //         },
    //         {
    //             path: 'edit/:id',
    //             name: 'edit-brands',
    //             component: () => import('../views/Brand.vue'),
    //             meta: {
    //                 title: 'Edit Brand',
    //                 family: 'brands',
    //                 auth: true
    //             }
    //         }
    //     ]
    // },
    {
        path: '/sync',
        name: 'sync',
        component: () => import('../views/Synchronous.vue'),
        meta: {
            title: 'Synchronous Page',
            role: ['admin'],
            hide: true,
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login Page',
            auth: false
        }
    }
]