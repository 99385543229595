<template>
  <div>
    <mainLayout v-if="isAuth && $store.getters.user"/>
    <authLayout v-if="!isAuth"/>
  </div>
</template>

<script>
import mainLayout from "@/layout/main-layout.vue";
import authLayout from "@/layout/auth-layout.vue";
import ls from '@/utils/ls'
export default {
  components: {
    mainLayout,
    authLayout
  },
  created() {
    if (ls.g('token')) {
      this.$store.dispatch('getMe').then(res => {}).catch(err => {
        this.$store.dispatch('logOut')
      })
    }
  },
  computed: {
    isAuth() {
      return this.$route.meta['auth'];
    }
  }
}
</script>

<style lang="scss">
</style>
