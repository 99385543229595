<template>
  <el-container class="main-container" v-if="$user">
    <portal to="page-title">
      {{ $t($route.meta.title || 'Default Page') }}
    </portal>
    <el-container>
      <SlideBar ref="slide"/>
      <el-container class="second-container">
        <AppBar @slide="(el) => $refs.slide.handle(el)"/>
        <el-main>
<!--          <transition name="fade">-->
            <router-view />
<!--          </transition>-->
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import SlideBar from "@/components/SlideBar.vue";
import AppBar from "@/components/AppBar.vue";
export default {
  name: "main-layout",
  components: {
    SlideBar,
    AppBar
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transform: translate(110%, 0);
  transition: opacity .3s, transform .7s;

}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate(110%, 0);
}

.main-container {
  height: 100vh;
  //background-color: #eeeeee;
}
.second-container {
  display: flex;
  flex-direction: column;
}


.el-main {
  margin: 25px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
}

</style>