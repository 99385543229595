<template>
  <el-aside :width="!isCollapse ? '251px' : '63px'">
    <div class="logo">
      <img v-if="!isCollapse" src="../../public/logo.jpg" alt="">
      <img v-else class="logo2" src="../../public/logo2.jpg" alt="">
    </div>
    <div class="user-section" :style="`display: ${isCollapse ? 'none' : 'flex'}`">
      <div style="margin-bottom: 10px">
        <el-avatar shape="square" :size="140" :src="avatar"></el-avatar>
      </div>
      <p style="margin-bottom: 5px">{{ user.firstName }} {{ user.lastName }}</p>
      <p style="margin-bottom: 5px" v-if="role !== 'admin'"><i class="el-icon-s-shop"/>{{ user.shop.name }}</p>
      <el-tag size="small" type="success">{{ role === 'admin' ? this.$t('Admin') : this.$t('Shopper') }}</el-tag>

<!--      <p><i class="el-icon-s-shop" /></p>-->


    </div>

    <el-menu :default-active="$route.meta.family" class="el-menu-vertical-demo" :collapse="isCollapse">
      <el-menu-item v-for="item in items" :index="item.family" :key="item.path" @click="$router.push({ name: item.name }).catch(() => {})">
        <i :class="item.icon || 'el-home'"></i>
        <span slot="title">{{ $t(item.title) }}</span>
      </el-menu-item>
    </el-menu>
  </el-aside>


</template>

<script>
import routes from "@/constants/routes";
export default {
  name: "SlideBar",
  data() {
    return {
      isCollapse: false
    };
  },
  methods: {
    handle(val) {
      this.isCollapse = val;
    }
  },
  computed: {
    role () {
      return this.$store.getters['user']['role'].description
    },
    items () {
      return routes.filter(route => route.meta && route.meta.auth && !route.meta.hide && route.meta.role.includes(this.role)).map(route => ({
        title: route.meta.title,
        path: route.path,
        icon: route.meta.icon,
        name: route.name,
        family: route.meta.family
      }));
    },
    user() {
      return this.$store.getters.user;
    },
    avatar () {
      // console.log(this.user)
      return this.user && this.user.avatar ?
          this.$iUrl + this.user.avatar['url'] :
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  text-align: center;
  margin-bottom: 20px;
  img {
    margin-top: 10px;
    width: 200px;
  }
  .logo2 {
    margin-top: 5px;
    width: 48px
  }
}
.user-section {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.el-aside {
  transition: all 1s;
  height: 100vh;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
}
</style>