import axios from 'axios'
import store from "@/store";
import ss from "@/utils/ls"
import router from "@/router";

let qs = require('qs')

function unauthorized(msg) {
    callError('Unauthorized')
    store.dispatch('logOut').then(res => {
        router.push({name: 'login'}).then(() => {
        })
    })
}

const callError = (error) => {
    store.dispatch('alert', {
        type: 'error',
        title: 'Something went wrong',
        content: error
    }).then(() => {
    })
}

function ErrorHandler(error) {
    if (error.message.startsWith('timeout')) {
        callError('Timeout error from server')
    }
    if (error.message.startsWith('Network Error')) {
        callError('Network error! Please check your internet connection')
    }
    if (error.response) {
        const _error = error.response.data
        switch (error.response.status) {
            case 400:
                callError(`Bad request: ${_error.error.message}`)
                break
            case 401:
                unauthorized()
                break
            case 403:
                callError(`Forbidden: ${_error.error.message}`)
                break
            case 404:
                callError(`Not found: ${_error.error.message}`)
                break
            case 500:
                callError(`Server error: ${_error.error.message}`)
                break
            case 502:
                callError(`Bad gateway: ${_error.error.message}`)
                break
            default:
                break
        }
    }
}

export default {
    request(method, url, params, data) {
        const config = {
            baseURL: process.env.VUE_APP_BASE_WIZART_URL,
            timeout: 120000,
            url: url,
            method: method,
            onUploadProgress: function (e) {
                const percent = Math.floor((e.loaded * 100) / e.total)
                store.commit('UPLOADING', percent)
            },
        }
        // if (ss.g('token') || store.getters.token) {
        config.headers = {
            Authorization: 'Bearer ' + process.env.VUE_APP_BASE_WIZART_TOKEN,
        }
        // }
        if (data) config.data = data

        if (params) {
            config.params = params
            config.paramsSerializer = function (params) {
                return qs.stringify(params, {encodeValuesOnly: true})
            }
        }

        const result = axios(config)

        return new Promise((resolve, reject) => {
            result
                .then((res) => {
                    // console.log('RES', res.data)
                    resolve(res)
                })
                .catch((error) => {
                    ErrorHandler(error)
                    reject(error)
                })
        })
    },
    get(url, params) {
        return this.request('GET', url, params, undefined)
    },
    post(url, data, params) {
        return this.request('POST', url, params, data)
    },
    patch(url, data, params) {
        return this.request('PATCH', url, params, data)
    },
    put(url, data, params) {
        return this.request('PUT', url, params, data)
    },
    remove(url, params) {
        return this.request('DELETE', url, params, undefined)
    }
}