import ai from "@/utils/ai";
import {camelize} from "@/utils";


export default {
    state: {
        syncs: {
            brand: {
                total: 0,
                syncing: false,
                start: false,
                count: 0,
                skipped: 0
            },
            collection: {
                total: 0,
                syncing: false,
                start: false,
                count: 0,
                skipped: 0
            },
            product: {
                total: 0,
                syncing: false,
                start: false,
                count: 0,
                skipped: 0
            }
        }
    },
    getters: {
        syncBrands: state => state['syncs'].brand,
        syncCollections: state => state['syncs'].collection,
        syncProducts: state => state['syncs'].product
    },
    mutations: {
        SET_SYNC_BRAND_DATA(state, payload) {
            state.syncs.brand = payload
        },
        SET_SYNC_COLLECTION_DATA(state, payload) {
            state.syncs.collection = payload
        },
        SET_SYNC_PRODUCT_DATA(state, payload) {
            state.syncs.product = payload
        }
    },
    actions: {
        async getSyncsData({ commit }) {
            try {
                const data = await ai.get('/sync/info')
                commit('SET_SYNC_BRAND_DATA', {
                    total: +data['syncBrandTotal'],
                    syncing: data['syncingBrands'],
                    start: data['syncStartBrands'],
                    count: +data['syncedBrandCount'],
                    skipped: +data['syncSkippedBrands']
                })
                commit('SET_SYNC_COLLECTION_DATA', {
                    total: +data['syncCollectionTotal'],
                    syncing: data['syncingCollections'],
                    start: data['syncStartCollections'],
                    count: +data['syncedCollectionCount'],
                    skipped: +data['syncSkippedCollection']
                })
                commit('SET_SYNC_PRODUCT_DATA', {
                    total: +data['syncProductTotal'],
                    syncing: data['syncingProducts'],
                    start: data['syncStartProducts'],
                    count: +data['syncedProductCount'],
                    skipped: +data['syncSkippedProduct']
                })
            } catch (e) {
                console.log('Error', e)
            }
        },
        async clearSyncData({ dispatch }) {
            try {
                await ai.patch('/sync/clear')
                dispatch('alert', {
                    type: 'success',
                    title: 'Success',
                    content: 'Sync data cleared successfully'
                })
                return
            } catch (e) {
                console.log('Error', e)
            }
        },
        async startSync({ dispatch }, type) {
            try {
                await ai.patch(`/sync/${type}`)
                dispatch('alert', {
                    type: 'info',
                    title: 'Syncing Started ...',
                    content: `Syncing ${type} started`
                })
                return
            } catch (e) {
                console.log('Error', e)
            }
        }
    }
}